import React from "react";
import { HeaderSection } from "./component/header/HeaderSection";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./theme/theme";
import "./App.css";
import Footer from "./component/footer/Footer";
import Introduction from "./component/introduction/Introduction";
import Intersection from "./component/intersection/Intersection";
import * as img1 from "./images/law.jpg";
import Skills from "./component/skills/Skills";
import Fees from "./component/fees/Fees";
import BuildingImg from "./images/building.jpg";
import LibraryImg from "./images/library.jpg";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <HeaderSection />
      <Introduction />
      <Intersection src={img1} alt={"Law"} />
      <Skills />
      <Intersection src={BuildingImg} alt={"Tribunal"} />
      <Fees />
      <Intersection src={LibraryImg} alt={"Library"} />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
