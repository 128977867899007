import * as React from 'react';
import './HeaderSection.css';
import { Grid, Typography, Grow } from '@material-ui/core';

export const HeaderSection = () => {
    return (
        <div className={"header-section"}>
            <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                <Grid item xs={12} md={12} style={{textAlign:"center"}}>
                    <Grow in={true} timeout={2000} ><Typography variant="h4">Elodie Dutour</Typography></Grow>
                    
                </Grid>
                <Grid item xs={12} md={12} style={{textAlign:"center"}}>
                    <Grow in={true} timeout={3000}>
                    <Typography variant="h2">Avocat en droit de la famille</Typography>
                    </Grow>
                </Grid>
            </Grid>
            </div>
    )
}