import * as React from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  List,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import Divorce from "../../images/skills/divorce.jpg";
import Patrimoine from "../../images/skills/patrimoine.jpg";
import Enfants from "../../images/skills/enfants.svg";
import Penal from "../../images/skills/penal.jpg";

export default function Skills() {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={4}
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
        <Typography variant="h4" style={{ fontWeight: 400 }}>
          Domaines d'intervention
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={12}
        justify="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={10} md={4}>
          <Card style={{ height: "100%" }}>
            <CardMedia
              style={{ height: 200 }}
              image={Divorce}
              title="Divorce"
            />
            <CardContent>
              <Typography variant="h6" style={{textAlign:"center"}}>Divorce et séparation</Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Divorce (contentieux ou par consentement mutuel)
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Dissolution de PACS
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Séparation de concubins
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={10} md={4}>
          <Card>
            <CardMedia
              style={{ height: 200 }}
              image={Patrimoine}
              title="Patrimoine"
            />
            <CardContent>
              <Typography variant="h6" style={{textAlign:"center"}}>Patrimoine</Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Liquidation des intérêts patrimoniaux
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Changement de régime matrimonial
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Convention de PACS ou de concubinage
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Conseils en matière patrimoniale
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={12}
        justify="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={10} md={4}>
          <Card>
            <CardMedia
              style={{ height: 200 }}
              image={Enfants}
              title="Enfants"
            />
            <CardContent>
              <Typography variant="h6" style={{textAlign:"center"}}>Enfants et filiation</Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Fixation et révision des modalités d'exercice de l'autorité
                        parentale (résidence habituelle,
                        droit de visite et d'hébergement, contribution à
                        l'entretien et l'éducation de l'enfant)
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Procédures en assistance éducative
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Procédures d'adoption
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Droits des grands-parents
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Recherche de maternité ou de paternité, contestation de paternité
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={10} md={4}>
          <Card style={{height:"100%"}}>
            <CardMedia
              style={{ height: 200 }}
              image={Penal}
              title="Pénal"
            />
            <CardContent>
              <Typography variant="h6" style={{textAlign:"center"}}>Droit pénal</Typography>
              
              <List>
                  <ListItem>
                      <ListItemText primary={<Typography variant="body2">Assistance des victimes et des auteurs d'infraction : </Typography>}/>
                  </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Au stade de l'enquête (Auditions libres et gardes à vue)
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Au cours de l'instruction
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body2">
                        Devant les juridictions de Jugement
                      </Typography>
                    }
                  />
                </ListItem>
                
              </List>
             
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
