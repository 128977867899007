import * as React from "react";
import "./Footer.css";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import SubwayIcon from "@material-ui/icons/Subway";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWF0dGhpbnRvc2giLCJhIjoiY2o5bnhldGhuMjc4YzJ5cGhrNnEwMnk2aCJ9.rGTDr9b58EbRbkUGSRv99Q";

const Footer = () => {
  let mapContainer = React.useRef();

  const [displayMentions, setDisplayMentions] = React.useState(false);

  const coordinates = [2.352849, 48.866385];

  //ComponentDidMount
  React.useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: coordinates,
      zoom: 17,
    });
    //Add Point
    map.on("load", function () {
      /* Image: An image is loaded and added to the map. */
      map.loadImage("https://i.imgur.com/MK4NUzI.png", function (error, image) {
        if (error) throw error;
        map.addImage("custom-marker", image);
        /* Style layer: A style layer ties together the source and image and specifies how they are displayed on the map. */
        map.addLayer({
          id: "markers",
          type: "symbol",
          /* Source: A data source specifies the geographic coordinate where the image marker gets placed. */
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: coordinates,
                  },
                },
              ],
            },
          },
          layout: {
            "icon-image": "custom-marker",
          },
        });
      });
    });
  });

  function displayLegalNotice() {
    return (
      <Dialog onClose={() => setDisplayMentions(false)} open={displayMentions}>
        <DialogTitle>Mentions légales</DialogTitle>
        <DialogContent>
          <Typography variant={"h6"}>Propriétaire du site : </Typography>
          <Typography variant="body1">Elodie Dutour</Typography>
          <Typography variant="body1">Avocat au Barreau de PARIS</Typography>
          <Typography variant="body1">
            92, Boulevard de Sébastopol - 75003 PARIS
          </Typography>
          <Typography variant="body1">FRANCE</Typography>
          <br />
          <Typography variant="body1">
            Membre d'un association de gestion agréée
          </Typography>
          <Typography variant="body1">SIRET : 809 658 842 00046</Typography>
          <br />
          <Typography variant="h6">Directeur de publication : </Typography>
          <Typography variant="body1">Elodie Dutour</Typography>
          <br />
          <Typography variant="body1">
            Le site internet wwww.dutour-avocat.fr relève du principe de la
            protection du Droit d'Auteur, posé par l'article L.111-1 du Code de
            la Propriété Intellectuelle. Il est la propriété exclusive d’Elodie
            DUTOUR.
          </Typography>
          <br />
          <Typography variant="body1">
            La totalité de son contenu est donc protégée. Aucune copie,
            reproduction, diffusion, modification, transmission (par
            téléchargement, email, ou tout autre support de communication) même
            partielle (autre que celle prévue à l'article L 122-5 du CPI*) de ce
            contenu ne peut être faite sans l'autorisation expresse d’Elodie
            DUTOUR.
          </Typography>
          <br />
          <Typography variant="h6">Hébergeur du site :</Typography>
          <Typography variant="body1">Firebase</Typography>
          <Typography variant="body1">Google France</Typography>
          <Typography variant="body1">
            8 rue de Londres - 75009 PARIS
          </Typography>
          <Typography variant="body1">https://firebase.google.com</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisplayMentions(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Grid
      style={{ backgroundColor: "#182f37", color: "white" }}
      direction="row"
      container
      spacing={4}
      justify="center"
      alignItems="center"
    >
      {displayLegalNotice()}
      <Grid item xs={12} md={12} style={{ textAlign: "center", marginTop: 20 }}>
        <Typography variant="h4">Accès</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <div ref={(el) => (mapContainer = el)} style={{ height: 350 }} />
      </Grid>
      <Grid item xs={10} md={4}>
        <Typography variant="subtitle1">Sur rendez-vous uniquement.</Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <LocationOnIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="h4">Adresse</Typography>}
              secondary={
                <Typography variant="body1">
                  92 Boulevard de Sébastopol - 75003 Paris
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SubwayIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="h4">Métro</Typography>}
              secondary={
                <Typography variant="body1">
                  Réaumur Sébastopol (Lignes 3 et 4) - Arts et Métiers (Lignes 3
                  et 11)
                </Typography>
              }
            ></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="h4">Contact</Typography>}
              secondary={
                <div style={{ color: "white" }}>
                  <Typography variant="body1">Tel : 01 81 80 04 27</Typography>
                  <Typography variant="body1">Fax : 01 76 54 86 26</Typography>
                </div>
              }
            ></ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <MailIcon style={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="h4">Courriel</Typography>}
              secondary={
                <Typography variant="body1">
                  e.dutour@dutour-avocat.fr
                </Typography>
              }
            ></ListItemText>
          </ListItem>
        </List>
      </Grid>
      <Grid xs={12} md={12} style={{ textAlign: "center", marginTop: 20 }}>
        <Button
          onClick={() => setDisplayMentions(true)}
          style={{ color: "white", textTransform: "none" }}
        >
          <Typography variant="caption">Mentions légales</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Footer;
