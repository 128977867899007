import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default function Fees() {
  return (
    <Grid
      container
      justify="center"
      alignItems="flex-start"
      spacing={4}
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
        <Typography variant="h4" style={{ fontWeight: 400 }}>
          Honoraires
        </Typography>
      </Grid>
      <Grid item xs={10} md={4} style={{ textAlign: "justify" }}>
        <Typography variant="body1">
          Conformément aux règles déontologiques de la profession, les
          honoraires du Cabinet sont déterminés selon plusieurs critères, parmi
          lesquels la nature et la difficulté du dossier, le temps devant lui
          être consacré, le travail de recherche, la ou les procédure(s)
          envisagée(s), et la situation personnelle du client.
        </Typography>
        <br />
        <Typography variant="body1">
          Selon le dossier, le montant des honoraires peut être forfaitaire, ou
          au temps passé, sur la base du taux horaire pratiqué par le Cabinet.
        </Typography>
        <br />
        <Typography variant="body1">
          Le cas échéant, ces modalités pourront être assorties d’un honoraire
          de résultat dont le pourcentage sera défini à l’avance.{" "}
        </Typography>
        <br />
      </Grid>
      <Grid item xs={10} md={4} style={{ textAlign: "justify" }}>
        <Typography variant="body1">
          Les modalités de facturation seront établies en concertation avec le
          client, et l’intervention du Cabinet fera toujours l'objet d'une
          convention d'honoraires, signée entre l’avocat et son client.
        </Typography>
        <br />
        <Typography variant="body1">
          La première consultation au Cabinet est facturée 240 € TTC.
        </Typography>
        <br />
        <Typography variant="body1">
          L’aide juridictionnelle est acceptée selon la situation.
        </Typography>
      </Grid>
    </Grid>
  );
}
