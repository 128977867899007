import * as React from 'react';
import "./Intersection.css";

export default function Intersection(props){

    return(
        <div className="intersection">
              <img src={props.src} alt={props.alt}/>  
        </div>
    )
}