import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import ElodieDutour from "../../images/introduction/elodieDutour.jpg";

export default function Introduction() {
  return (
    <Grid
      container
      justify="center"
      alignItems="flex-start"
      spacing={4}
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      <Grid item xs={10} md={12} style={{ textAlign: "center" }}>
        <Typography variant="h4" style={{ fontWeight: 400 }}>
          Votre avocat
        </Typography>
      </Grid>
      <Grid item xs={10} md={3} style={{ textAlign: "center" }}>
        <img src={ElodieDutour} alt="Elodie Dutour" height={400} />
      </Grid>
      <Grid item xs={10} md={3} style={{ textAlign: "justify" }}>
        <Typography variant="body1">
          Avocat au Barreau de Paris, Maître Elodie DUTOUR intervient en matière
          de conseil et de contentieux, principalement en droit de la famille,
          des personnes et de leur patrimoine.
        </Typography>
        <br />
        <Typography variant="body1">
          Elle a suivi ses études au sein de l’Université de CERGY PONTOISE où
          elle a obtenu une maitrise en droit privé général, puis au sein de
          l’Université PARIS 1 PANTHÉON SORBONNE où elle a obtenu le master
          « Personne et droit », avant d’obtenir l’examen du Barreau et
          d’intégrer l’EFB.
        </Typography>
        <br />
        <Typography variant="body1">
          Après plusieurs années de collaboration au sein de Cabinets
          spécialisés en droit de la famille, des personnes et de leur
          patrimoine, Maître Elodie DUTOUR a fondé son Cabinet en 2019 et exerce
          désormais à titre individuel dans ce domaine.
        </Typography>
      </Grid>
      <Grid item xs={10} md={3} style={{ textAlign: "justify" }}>
        <Typography variant="body1">
          Maître Élodie DUTOUR s’engage à traiter ses dossiers avec exigence,
          réactivité, disponibilité, et adapte son intervention au mieux des
          besoins spécifiques de chacun de ses clients.
        </Typography>
        <br />
        <Typography variant="body1">
          Elle intervient devant les juridictions de la région parisienne ainsi
          que sur l’ensemble du territoire français.
        </Typography>
      </Grid>
    </Grid>
  );
}
